<template>
  <el-dialog
    :title="titleDialog"
    :visible="showDialog"
    @close="close"
    @open="create"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    append-to-body
    width="40%"
  >
    <form autocomplete="off" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="form-group" :class="{ 'error--text': errors.name }">
            <label>Nombre</label>
            <el-input v-model="form.name"></el-input>
            <small
              class="error--text"
              v-if="errors.name"
              v-text="errors.name[0]"
            ></small>
          </div>
        </v-col>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="form-group" :class="{ 'error--text': errors.delivery }">
            <label>vender por Delivery</label><br />
            <el-switch
              v-model="form.delivery"
              active-text="Si"
              inactive-text="No"
            ></el-switch>
            <small
              class="error--text"
              v-if="errors.delivery"
              v-text="errors.delivery[0]"
            ></small>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
		<v-col cols="12" xl="12" lg="12" md="12" sm="12">
			<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
			<el-button type="primary" native-type="submit" :loading="loading">
			  <template v-if="loading">
				Guardando...
			  </template>
			  <template v-else>
				Guardar
			  </template>
			</el-button>
		</v-col>
      </v-row>
    </form>
  </el-dialog>
</template>

<script>
export default {
  props: ["showDialog", "recordId", "external"],
  data() {
    return {
      loading: false,
      titleDialog: null,
      resource: "categories",
      valid: false,
      errors: {},
      form: {},
    };
  },
  created() {},
  methods: {
    initForm() {
      this.errors = {};
      this.form = {
        id: null,
        name: null,
        delivery: false,
      };
    },
    create() {
      this.titleDialog = this.recordId ? "Editar categoría" : "Nueva categoría";
      if (this.recordId) {
        this.$http
          .get(`/${this.resource}/record/${this.recordId}`)
          .then((response) => {
            this.form = response.data.data;
          });
      }
    },
    submit() {
      this.loading = true;
      this.$http
        .post(`/${this.resource}`, this.form)
        .then((response) => {
          if (response.data.success) {
            this.$message.success(response.data.message);
            if (this.external) {
              this.$eventHub.$emit("reloadDataCategories", response.data.id);
            } else {
              this.$eventHub.$emit("reloadData", this.resource);
            }
            this.close();
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          } else {
            this.$message.error(error.response.data.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("update:showDialog", false);
      this.initForm();
    },
  },
};
</script>
